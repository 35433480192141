<template>
  <div v-if="showResults" class="mt-6">
    <h2 class="text-xl font-semibold mb-4">Results</h2>
    <div class="bg-white shadow overflow-hidden sm:rounded-lg">
      <div class="px-4 py-5 sm:p-6">
        <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          <div v-for="(value, key) in results" :key="key" class="sm:col-span-1">
            <dt class="text-sm font-medium text-gray-500 flex items-center">
              {{ resultLabels[key] }}
              <button
                @click="$emit('showTooltip', key, tooltips[key])"
                class="ml-1 text-gray-400 hover:text-gray-500"
              >
                <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
                </svg>
              </button>
            </dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{ formatResult(key, value) }}
            </dd>
          </div>
        </dl>
      </div>
    </div>
    <div class="mt-4 flex space-x-2">
      <button
        @click="$emit('saveCurrentData')"
        class="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
      >
        Save
      </button>
      <button
        @click="$emit('printResults')"
        class="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
      >
        Print
      </button>
      <button
        v-if="mortgageType === 'repayment'"
        @click="$emit('showAmortizationSchedule')"
        class="px-4 py-2 bg-white text-black border border-black rounded-md hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
      >
        View Amortization Schedule
      </button>
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue'

const props = defineProps({
  showResults: Boolean,
  results: Object,
  resultLabels: Object,
  tooltips: Object,
  mortgageType: String
})

const emit = defineEmits(['showTooltip', 'showAmortizationSchedule', 'saveCurrentData', 'printResults'])

const formatResult = (key, value) => {
  if (key.includes('Rate') || key.includes('ROI') || key === 'cashOnCashReturn') {
    return (value * 100).toFixed(2) + '%'
  } else {
    return '£' + value.toFixed(2)
  }
}
</script>

<style scoped>
/* Add any component-specific styles here */
</style>