<template>
  <form @submit.prevent class="space-y-4">
    <div v-for="field in fields" :key="field.id" class="relative">
      <label :for="field.id" class="block text-sm font-medium text-gray-700 mb-1">{{ field.label }}</label>
      <div class="mt-1 relative rounded-md shadow-sm">
        <div v-if="field.prefix || field.suffix === '%'" class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <span class="text-gray-500 sm:text-sm">{{ field.prefix || field.suffix }}</span>
        </div>
        <template v-if="field.type === 'select'">
          <select
            :id="field.id"
            v-model="field.value"
            @change="onSelectChange(field)"
            class="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-cyan-500 focus:border-cyan-500 sm:text-sm rounded-md"
          >
            <option v-for="option in field.options" :key="option.value" :value="option.value">
              {{ option.label }}
            </option>
          </select>
        </template>
        <template v-else>
          <input
            :value="field.value"
            :type="field.type"
            :id="field.id"
            :step="field.step"
            required
            @focus="onFocus(field)"
            @blur="onBlur(field, $event)"
            @input="onInput(field, $event)"
            :class="['block rounded-md sm:text-sm border-gray-300 focus:ring-cyan-500 focus:border-cyan-500', 
                     {'border-red-500': field.error},
                     (field.prefix || field.suffix === '%') ? 'pl-7' : 'pl-3',
                     'pr-10 w-full']"
            :aria-describedby="`${field.id}-error`"
          />
        </template>
        <div class="absolute inset-y-0 right-0 flex items-center">
          <button 
            type="button" 
            @click="$emit('showTooltip', field.id, field.tooltip)"
            class="h-full px-2 rounded-r-md focus:outline-none"
            aria-label="Show info"
          >
            <svg class="h-5 w-5 text-gray-400" fill="currentColor" viewBox="0 0 20 20">
              <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd" />
            </svg>
          </button>
        </div>
      </div>
      <p v-if="field.error" :id="`${field.id}-error`" class="mt-1 text-xs text-red-500">{{ field.error }}</p>
    </div>
  </form>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue'

const props = defineProps({
  fields: {
    type: Array,
    required: true
  }
})

const emit = defineEmits(['update:fields', 'showTooltip', 'updateCalculations'])

const onFocus = (field) => {
  if (field.value === 0) {
    field.value = ''
  }
}

const onBlur = (field, event) => {
  if (event.target.value === '') {
    field.value = 0
  } else {
    field.value = Number(event.target.value)
  }
  validateField(field, true)
  emit('updateCalculations')
}

const onSelectChange = (field) => {
  emit('updateCalculations')
}

let debounceTimer

const onInput = (field, event) => {
  field.value = event.target.value === '' ? '' : Number(event.target.value)
  clearTimeout(debounceTimer)
  debounceTimer = setTimeout(() => {
    emit('updateCalculations')
  }, 2000)
}

const validateField = (field, onBlur = false) => {
  if (!onBlur) return
  field.error = ''
  const value = Number(field.value)

  switch (field.id) {
    case 'propertyPrice':
      if (value < 50000) field.error = 'Property price seems low. Please verify.'
      break
    case 'mortgageRate':
      if (value > 20) field.error = 'Interest rate seems high. Please verify.'
      break
    case 'downPayment':
      if (value < 0 || value > 100) field.error = 'Down payment must be between 0% and 100%.'
      break
    case 'vacancyRate':
      if (value < 0 || value > 100) field.error = 'Vacancy rate must be between 0% and 100%.'
      break
    case 'loanTerm':
      if (value < 1 || value > 40) field.error = 'Loan term must be between 1 and 40 years.'
      break
  }
}
</script>

<style scoped>
input[type="number"] {
  padding-right: 2.5rem;
}
select {
  padding-right: 3.5rem;
  background-position: right 2rem center;
  appearance: none;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
}
</style>