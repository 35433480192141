<template>
  <div v-if="savedEntries.length > 0" class="mt-6">
    <h3 class="text-lg font-medium text-gray-900 mb-2">Saved Entries</h3>
    <div class="mt-4">
      <div v-for="(entry, index) in savedEntries" :key="index" class="bg-white shadow rounded-lg p-4 mb-4">
        <div class="flex justify-between items-start mb-2">
          <h4 class="text-md font-semibold">{{ getEntryDescription(entry) }}</h4>
          <div class="flex space-x-2">
            <input 
              type="checkbox" 
              :id="`entry-${index}`" 
              v-model="entry.selected"
              class="rounded text-cyan-600 focus:ring-cyan-500"
            />
            <button 
              @click="$emit('loadSelectedEntry', index)" 
              class="p-1 text-yellow-600 hover:bg-yellow-100 rounded-full focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2"
              title="Load Entry"
            >
              <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd" />
              </svg>
            </button>
            <button 
              @click="$emit('deleteEntry', index)" 
              class="p-1 text-red-600 hover:bg-red-100 rounded-full focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
              title="Delete Entry"
            >
              <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd" />
              </svg>
            </button>
          </div>
        </div>
        <div class="grid grid-cols-2 gap-2 text-sm">
          <div v-for="(value, key) in getEntrySummary(entry)" :key="key" class="contents">
            <div class="font-medium text-gray-700">{{ key }}:</div>
            <div class="text-gray-900">{{ value }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-4 space-x-2">
      <button 
        @click="$emit('compareSelectedEntries')" 
        class="p-2 bg-cyan-500 text-white rounded-md hover:bg-cyan-600 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed"
        :disabled="selectedEntriesCount < 2"
        title="Compare Selected Entries"
      >
        Compare selection
      </button>
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, computed } from 'vue'

const props = defineProps({
  savedEntries: Array,
  resultLabels: Object
})

const emit = defineEmits(['deleteEntry', 'compareSelectedEntries', 'loadSelectedEntry'])

const selectedEntriesCount = computed(() => {
  return props.savedEntries.filter(entry => entry.selected).length
})

const getEntrySummary = (entry) => {
  const summary = {}
  const allEntries = props.savedEntries
  
  // Compare inputs
  entry.fields.forEach(field => {
    const isDifferent = allEntries.some(e => e.fields.find(f => f.id === field.id).value !== field.value)
    if (isDifferent) {
      summary[field.label] = field.value
    }
  })
  
  // Compare results
  Object.keys(entry.results).forEach(key => {
    const isDifferent = allEntries.some(e => e.results[key] !== entry.results[key])
    if (isDifferent) {
      const value = entry.results[key]
      if (key.includes('Rate') || key.includes('ROI') || key === 'cashOnCashReturn') {
        summary[props.resultLabels[key]] = (value * 100).toFixed(2) + '%'
      } else {
        summary[props.resultLabels[key]] = '£' + value.toFixed(2)
      }
    }
  })
  
  return summary
}

const getEntryDescription = (entry) => {
  const differentFields = entry.fields.filter(field => 
    props.savedEntries.some(e => e.fields.find(f => f.id === field.id).value !== field.value)
  )

  if (differentFields.length === 0) {
    return 'Default Entry'
  }

  const description = differentFields.map(field => {
    let value = field.value
    if (field.prefix) {
      value = `${field.prefix}${value}`
    }
    if (field.suffix) {
      value = `${value}${field.suffix}`
    }
    return `${field.label}: ${value}`
  }).join(', ')

  return `For ${description}`
}
</script>