<template>
  <div class="min-h-screen bg-gray-100 py-6 flex flex-col justify-center sm:py-12">
    <div class="relative py-3 sm:max-w-xl sm:mx-auto">
      <div class="absolute inset-0 bg-gradient-to-r from-cyan-400 to-light-blue-500 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl"></div>
      <div class="relative px-4 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-20" ref="calculatorCard">
        <div class="max-w-md mx-auto">
          <h1 class="text-2xl font-semibold mb-6 text-center">UK Property Rental Calculator</h1>
          
          <InputForm 
            :fields="fields" 
            @update:fields="updateFields"
            @show-tooltip="showTooltip"
            @update-calculations="updateCalculations"
          />
          
          <ResultsDisplay 
            :show-results="showResults"
            :results="results"
            :result-labels="resultLabels"
            :tooltips="tooltips"
            :mortgage-type="mortgageType"
            @show-tooltip="showTooltip"
            @show-amortization-schedule="showAmortizationSchedule = true"
            @save-current-data="saveCurrentData"
            @print-results="printResults"
          />

          <div class="mt-6">
            <h3 class="text-lg font-medium text-gray-900 mb-2">Share this calculation</h3>
            <div class="flex items-center space-x-2">
              <input 
                type="text" 
                :value="currentUrl" 
                readonly 
                class="flex-grow px-3 py-2 bg-gray-100 rounded-md text-sm"
              />
              <button 
                @click="copyToClipboard" 
                class="p-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                title="Copy to Clipboard"
              >
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                  <path d="M8 3a1 1 0 011-1h2a1 1 0 110 2H9a1 1 0 01-1-1z" />
                  <path d="M6 3a2 2 0 00-2 2v11a2 2 0 002 2h8a2 2 0 002-2V5a2 2 0 00-2-2 3 3 0 01-3 3H9a3 3 0 01-3-3z" />
                </svg>
              </button>
            </div>
            <div class="mt-2 flex space-x-2">
              <a :href="linkedInShareUrl" target="_blank" rel="noopener noreferrer" class="text-blue-600 hover:underline">
                Share on LinkedIn
              </a>
              <a :href="emailShareUrl" class="text-blue-600 hover:underline">
                Share via Email
              </a>
              <a :href="whatsAppShareUrl" target="_blank" rel="noopener noreferrer" class="text-green-600 hover:underline">
                Share on WhatsApp
              </a>
            </div>
          </div>

          <SavedEntries 
            :saved-entries="savedEntries"
            :result-labels="resultLabels"
            @delete-entry="deleteEntry"
            @compare-selected-entries="compareSelectedEntries"
            @load-selected-entry="loadSelectedEntry"
          />
        </div>
      </div>
    </div>
    
    <TooltipModal 
      :show="!!activeTooltip"
      :content="activeTooltipContent"
      @close="hideTooltip"
    />

    <AmortizationScheduleModal 
      :show="showAmortizationSchedule"
      :schedule="amortizationSchedule"
      @close="showAmortizationSchedule = false"
    />

    <ComparisonModal 
      :show="showComparison"
      :entries="selectedEntries"
      :result-labels="resultLabels"
      @close="showComparison = false"
    />

    <PrintableContent
      ref="printableContent"
      :fields="fields"
      :results="results"
      :result-labels="resultLabels"
      :saved-entries="savedEntries"
      :amortization-schedule="amortizationSchedule"
      :mortgage-type="mortgageType"
      :tooltips="tooltips"
    />
  </div>
</template>

<script setup>
import { ref, reactive, computed, onMounted, watch } from 'vue'
import html2pdf from 'html2pdf.js'
import InputForm from './InputForm.vue'
import ResultsDisplay from './ResultsDisplay.vue'
import SavedEntries from './SavedEntries.vue'
import TooltipModal from './TooltipModal.vue'
import AmortizationScheduleModal from './AmortizationScheduleModal.vue'
import ComparisonModal from './ComparisonModal.vue'
import PrintableContent from './PrintableContent.vue'

const fields = ref([
  { id: 'propertyPrice', label: 'Property Price', value: 0, type: 'number', step: '1000', tooltip: 'Enter the purchase price of the property in GBP. Typical UK property prices range from £100,000 to £1,000,000+.', error: '', prefix: '£', abbr: 'pp' },
  { id: 'monthlyRent', label: 'Expected Monthly Rent', value: 0, type: 'number', step: '10', tooltip: 'Enter the expected monthly rental income in GBP. This typically ranges from 0.5% to 1% of the property value per month.', error: '', prefix: '£', abbr: 'emr' },
  { id: 'mortgageType', label: 'Mortgage Type', value: 'repayment', type: 'select', options: [
    { value: 'repayment', label: 'Repayment' },
    { value: 'interestOnly', label: 'Interest Only' }
  ], tooltip: 'Select the type of mortgage. Repayment mortgages pay off the loan over time, while interest-only mortgages only pay the interest.', error: '', abbr: 'mt' },
  { id: 'mortgageRate', label: 'Mortgage Interest Rate', value: 0, type: 'number', step: '0.1', tooltip: 'Enter the annual interest rate of your mortgage as a percentage. Typical UK rates range from 2% to 6%.', error: '', suffix: '%', abbr: 'mir' },
  { id: 'downPayment', label: 'Down Payment', value: 0, type: 'number', step: '1', tooltip: 'Enter your down payment as a percentage of the property price. Typical range is 10% to 40%.', error: '', suffix: '%', abbr: 'dp' },
  { id: 'loanTerm', label: 'Loan Term (years)', value: 25, type: 'number', step: '1', tooltip: 'Enter the loan term in years. Typical mortgage terms in the UK range from 25 to 35 years.', error: '', abbr: 'lt' },
  { id: 'councilTax', label: 'Monthly Council Tax', value: 0, type: 'number', step: '10', tooltip: 'Enter the monthly council tax in GBP. This varies by property and location, typically ranging from £60 to £300 per month.', error: '', prefix: '£', abbr: 'mct' },
  { id: 'insurance', label: 'Annual Insurance', value: 0, type: 'number', step: '10', tooltip: 'Enter the annual property insurance cost in GBP. This typically ranges from £100 to £500 per year.', error: '', prefix: '£', abbr: 'ai' },
  { id: 'maintenance', label: 'Monthly Maintenance', value: 0, type: 'number', step: '10', tooltip: 'Enter estimated monthly maintenance costs in GBP. A common rule of thumb is 1% of property value per year, divided by 12.', error: '', prefix: '£', abbr: 'mm' },
  { id: 'managementFee', label: 'Monthly Management Fee', value: 0, type: 'number', step: '10', tooltip: 'If using a property management service, enter their monthly fee in GBP. Typically 8-12% of monthly rent.', error: '', prefix: '£', abbr: 'mmf' },
  { id: 'vacancyRate', label: 'Expected Vacancy Rate', value: 0, type: 'number', step: '1', tooltip: 'Enter the expected percentage of time the property will be vacant per year. Typical range is 4% to 8%.', error: '', suffix: '%', abbr: 'evr' },
])

const showResults = ref(false)
const results = reactive({
  monthlyCashFlowOccupied: 0,
  monthlyCashFlowVacant: 0,
  annualCashFlow: 0,
  capRate: 0,
  cashOnCashReturn: 0,
  totalROI: 0,
  monthlyMortgagePayment: 0
})

const resultLabels = {
  monthlyCashFlowOccupied: 'Monthly Cash Flow (Occupied)',
  monthlyCashFlowVacant: 'Monthly Cash Flow (Vacant)',
  annualCashFlow: 'Annual Cash Flow',
  capRate: 'Cap Rate',
  cashOnCashReturn: 'Cash on Cash Return',
  totalROI: 'Total Return on Investment',
  monthlyMortgagePayment: 'Monthly Mortgage Payment'
}

const tooltips = {
  monthlyCashFlowOccupied: 'Monthly income minus expenses when the property is occupied. Calculated as: Monthly Rent - (Mortgage + Maintenance + (Insurance / 12) + Management Fee)',
  monthlyCashFlowVacant: 'Monthly expenses when the property is vacant. Calculated as: -(Mortgage + Maintenance + (Insurance / 12) + Management Fee + Council Tax)',
  annualCashFlow: 'Total annual cash flow, accounting for both occupied and vacant periods. Calculated as: (Monthly Cash Flow Occupied * Occupied Months) + (Monthly Cash Flow Vacant * Vacant Months)',
  capRate: 'Annual net operating income divided by property value. Measures the rate of return on the property investment. Calculated as: Annual Cash Flow / Property Price',
  cashOnCashReturn: 'Annual pre-tax cash flow divided by total cash invested (down payment + closing costs). Measures the efficiency of cash invested. Calculated as: Annual Cash Flow / (Down Payment + Closing Costs)',
  totalROI: 'Total return on investment, including cash flow and appreciation. Calculated as: (Annual Cash Flow + Annual Appreciation) / Total Cash Invested. Assumes 3% annual appreciation.',
  monthlyMortgagePayment: 'The monthly payment required to repay the mortgage over the loan term.'
}

const activeTooltip = ref(null)
const activeTooltipContent = ref('')
let tooltipTimer = null

const showTooltip = (id, content) => {
  if (tooltipTimer) {
    clearTimeout(tooltipTimer)
  }
  activeTooltip.value = id
  activeTooltipContent.value = content
  tooltipTimer = setTimeout(() => {
    hideTooltip()
  }, 10000)
}

const hideTooltip = () => {
  activeTooltip.value = null
  activeTooltipContent.value = ''
  if (tooltipTimer) {
    clearTimeout(tooltipTimer)
  }
}

const loanAmount = computed(() => {
  const propertyPrice = fields.value.find(f => f.id === 'propertyPrice').value
  const downPayment = fields.value.find(f => f.id === 'downPayment').value
  return propertyPrice * (1 - downPayment / 100)
})

const monthlyMortgage = computed(() => {
  const mortgageRate = fields.value.find(f => f.id === 'mortgageRate').value
  const loanTerm = fields.value.find(f => f.id === 'loanTerm').value
  const mortgageType = fields.value.find(f => f.id === 'mortgageType').value
  const r = mortgageRate / 100 / 12
  const n = loanTerm * 12

  if (mortgageType === 'interestOnly') {
    return loanAmount.value * r
  } else {
    return (loanAmount.value * r * Math.pow(1 + r, n)) / (Math.pow(1 + r, n) - 1)
  }
})

const amortizationSchedule = ref([])
const showAmortizationSchedule = ref(false)

const calculateAmortizationSchedule = () => {
  const mortgageRate = fields.value.find(f => f.id === 'mortgageRate').value
  const loanTerm = fields.value.find(f => f.id === 'loanTerm').value
  const mortgageType = fields.value.find(f => f.id === 'mortgageType').value
  const monthlyRate = mortgageRate / 100 / 12
  let remainingBalance = loanAmount.value
  let schedule = []

  for (let year = 1; year <= loanTerm; year++) {
    let yearlyInterestPaid = 0
    let yearlyPrincipalPaid = 0
    const beginningBalance = remainingBalance

    for (let month = 1; month <= 12; month++) {
      const interestPayment = remainingBalance * monthlyRate
      let principalPayment = 0

      if (mortgageType === 'repayment') {
        principalPayment = monthlyMortgage.value - interestPayment
        remainingBalance -= principalPayment
      }
      
      yearlyInterestPaid += interestPayment
      yearlyPrincipalPaid += principalPayment
    }

    schedule.push({
      year,
      beginningBalance,
      interestPaid: yearlyInterestPaid,
      principalPaid: yearlyPrincipalPaid,
      endingBalance: remainingBalance
    })
  }

  amortizationSchedule.value = schedule
}

const updateCalculations = () => {
  const propertyPrice = fields.value.find(f => f.id === 'propertyPrice').value
  const monthlyRent = fields.value.find(f => f.id === 'monthlyRent').value
  const councilTax = fields.value.find(f => f.id === 'councilTax').value
  const insurance = fields.value.find(f => f.id === 'insurance').value
  const maintenance = fields.value.find(f => f.id === 'maintenance').value
  const managementFee = fields.value.find(f => f.id === 'managementFee').value
  const vacancyRate = fields.value.find(f => f.id === 'vacancyRate').value

  const monthlyExpensesOccupied = monthlyMortgage.value + maintenance + (insurance  / 12) + managementFee
  const monthlyExpensesVacant = monthlyExpensesOccupied + councilTax

  const monthlyCashFlowOccupied = monthlyRent - monthlyExpensesOccupied
  const monthlyCashFlowVacant = -monthlyExpensesVacant

  const occupiedMonths = 12 * (1 - vacancyRate / 100)
  const vacantMonths = 12 - occupiedMonths

  const annualCashFlow = (monthlyCashFlowOccupied * occupiedMonths) + (monthlyCashFlowVacant * vacantMonths)

  const totalCashInvested = (propertyPrice * (fields.value.find(f => f.id === 'downPayment').value / 100)) + (propertyPrice * 0.03) // Down payment + 3% closing costs

  results.monthlyCashFlowOccupied = monthlyCashFlowOccupied
  results.monthlyCashFlowVacant = monthlyCashFlowVacant
  results.annualCashFlow = annualCashFlow
  results.capRate = annualCashFlow / propertyPrice
  results.cashOnCashReturn = annualCashFlow / totalCashInvested
  results.totalROI = (annualCashFlow + (propertyPrice * 0.03)) / totalCashInvested // Assuming 3% annual appreciation
  results.monthlyMortgagePayment = monthlyMortgage.value

  calculateAmortizationSchedule()

  showResults.value = Object.values(results).some(value => !isNaN(value) && value !== 0)
}

const saveToLocal = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value))
}

const loadFromLocal = (key) => {
  const data = localStorage.getItem(key)
  return data ? JSON.parse(data) : null
}

const saveToLocalStorage = () => {
  const dataToSave = fields.value.reduce((acc, field) => {
    acc[field.id] = field.value
    return acc
  }, {})
  saveToLocal('propertyCalculatorData', dataToSave)
}

const loadFromLocalStorage = () => {
  const savedData = loadFromLocal('propertyCalculatorData')
  if (savedData) {
    fields.value.forEach(field => {
      if (Object.prototype.hasOwnProperty.call(savedData, field.id)) {
        field.value = savedData[field.id]
      }
    })
    updateCalculations()
  }
}

const updateUrlHash = () => {
  const hashParams = fields.value.map(field => `${field.abbr}=${field.value}`).join('&')
  window.history.replaceState(null, null, `#${hashParams}`)
}

const loadFromUrlHash = () => {
  const hash = window.location.hash.slice(1)
  if (hash) {
    const params = new URLSearchParams(hash)
    fields.value.forEach(field => {
      const value = params.get(field.abbr)
      if (value !== null) {
        field.value = field.type === 'select' ? value : Number(value)
      }
    })
    updateCalculations()
  }
}

const currentUrl = ref('')

const updateCurrentUrl = () => {
  currentUrl.value = window.location.href
}

const copyToClipboard = () => {
  navigator.clipboard.writeText(currentUrl.value)
    .then(() => {
      alert('URL copied to clipboard!')
    })
    .catch(err => {
      console.error('Failed to copy URL: ', err)
    })
}

const linkedInShareUrl = computed(() => {
  return `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(currentUrl.value)}`
})

const emailShareUrl = computed(() => {
  const subject = encodeURIComponent('UK Property Rental Calculator Results')
  const body = encodeURIComponent(`Check out my property rental calculation: ${currentUrl.value}`)
  return `mailto:?subject=${subject}&body=${body}`
})

const whatsAppShareUrl = computed(() => {
  return `https://wa.me/?text=${encodeURIComponent(`Check out my property rental calculation: ${currentUrl.value}`)}`
})

const savedEntries = ref([])
const showComparison = ref(false)

const saveCurrentData = () => {
  const currentEntry = {
    fields: fields.value.map(field => ({ ...field })),
    results: { ...results },
    selected: false
  }
  savedEntries.value.push(currentEntry)
  saveToLocal('savedEntries', savedEntries.value)
}

const selectedEntriesCount = computed(() => {
  return savedEntries.value.filter(entry => entry.selected).length
})

const selectedEntries = computed(() => {
  return savedEntries.value.filter(entry => entry.selected)
})

const compareSelectedEntries = () => {
  if (selectedEntriesCount.value >= 2) {
    showComparison.value = true
  }
}

const calculatorCard = ref(null)

const loadSelectedEntry = (index) => {
  const selectedEntry = savedEntries.value[index]
  fields.value.forEach(field => {
    const savedField = selectedEntry.fields.find(f => f.id === field.id)
    if (savedField) {
      field.value = savedField.value
    }
  })
  updateCalculations()
  saveToLocalStorage()
  updateUrlHash()
  scrollToTop()
}

const scrollToTop = () => {
  if (calculatorCard.value) {
    calculatorCard.value.scrollIntoView({ behavior: 'smooth' })
  }
}

const deleteEntry = (index) => {
  savedEntries.value.splice(index, 1)
  saveToLocal('savedEntries', savedEntries.value)
}

const updateFields = (updatedFields) => {
  fields.value = updatedFields
}

const mortgageType = computed(() => {
  return fields.value.find(f => f.id === 'mortgageType').value
})

const printableContent = ref(null)

const printResults = () => {
  const element = printableContent.value.$el
  const opt = {
    margin: 10,
    filename: 'uk-property-rental-calculator-results.pdf',
    image: { type: 'jpeg', quality: 0.98 },
    html2canvas: { scale: 2 },
    jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
  }

  html2pdf().from(element).set(opt).toPdf().get('pdf').then((pdf) => {
    const blob = new Blob([pdf.output('blob')], { type: 'application/pdf' })
    const blobUrl = URL.createObjectURL(blob)
    window.open(blobUrl, '_blank')
  }).catch(err => console.error('Error generating PDF:', err))
}

onMounted(() => {
  loadFromUrlHash() // First, try to load from URL hash
  if (!fields.value.some(field => field.value !== 0)) {
    loadFromLocalStorage() // If URL hash is empty, load from localStorage
  }
  updateUrlHash() // Update URL hash based on loaded values
  updateCurrentUrl() // Set the current URL
  savedEntries.value = loadFromLocal('savedEntries') || []
})

watch(fields, () => {
  saveToLocalStorage()
  updateUrlHash()
  updateCurrentUrl()
}, { deep: true })

watch(savedEntries, () => {
  saveToLocal('savedEntries', savedEntries.value)
}, { deep: true })

</script>