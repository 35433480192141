<template>
  <div class="printable-content">
    <h1 class="text-2xl font-bold mb-4">UK Property Rental Calculator Results</h1>
    
    <h2 class="text-xl font-semibold mb-2">Current Calculation</h2>
    <div class="mb-4">
      <h3 class="text-lg font-medium mb-1">Inputs</h3>
      <div v-for="field in fields" :key="field.id" class="grid grid-cols-2 gap-2">
        <span class="font-medium">{{ field.label }}:</span>
        <span>{{ field.value }}{{ field.prefix || '' }}{{ field.suffix || '' }}</span>
      </div>
    </div>
    
    <div class="mb-4">
      <h3 class="text-lg font-medium mb-1">Results</h3>
      <div v-for="(value, key) in results" :key="key" class="grid grid-cols-2 gap-2">
        <span class="font-medium">{{ resultLabels[key] }}:</span>
        <span>{{ formatResult(key, value) }}</span>
      </div>
    </div>
    
    <div v-if="mortgageType === 'repayment'" class="mb-4">
      <h3 class="text-lg font-medium mb-1">Amortization Schedule</h3>
      <table class="w-full border-collapse border border-gray-300">
        <thead>
          <tr>
            <th class="border border-gray-300 p-2">Year</th>
            <th class="border border-gray-300 p-2">Beginning Balance</th>
            <th class="border border-gray-300 p-2">Interest Paid</th>
            <th class="border border-gray-300 p-2">Principal Paid</th>
            <th class="border border-gray-300 p-2">Ending Balance</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in amortizationSchedule" :key="row.year">
            <td class="border border-gray-300 p-2">{{ row.year }}</td>
            <td class="border border-gray-300 p-2">£{{ row.beginningBalance.toFixed(2) }}</td>
            <td class="border border-gray-300 p-2">£{{ row.interestPaid.toFixed(2) }}</td>
            <td class="border border-gray-300 p-2">£{{ row.principalPaid.toFixed(2) }}</td>
            <td class="border border-gray-300 p-2">£{{ row.endingBalance.toFixed(2) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    
    <h2 class="text-xl font-semibold mt-6 mb-2">Saved Entries</h2>
    <div v-for="(entry, index) in savedEntries" :key="index" class="mb-4">
      <h3 class="text-lg font-medium mb-1">{{ getEntryDescription(entry, index) }}</h3>
      <div v-for="field in entry.fields" :key="field.id" class="grid grid-cols-2 gap-2">
        <span class="font-medium">{{ field.label }}:</span>
        <span>{{ field.value }}{{ field.prefix || '' }}{{ field.suffix || '' }}</span>
      </div>
      <div class="mt-2">
        <h4 class="text-md font-medium mb-1">Results</h4>
        <div v-for="(value, key) in entry.results" :key="key" class="grid grid-cols-2 gap-2">
          <span class="font-medium">{{ resultLabels[key] }}:</span>
          <span>{{ formatResult(key, value) }}</span>
        </div>
      </div>
    </div>
    
    <h2 class="text-xl font-semibold mt-6 mb-2">Comparison of Saved Entries</h2>
    <table class="w-full border-collapse border border-gray-300">
      <thead>
        <tr>
          <th class="border border-gray-300 p-2">Metric</th>
          <th v-for="(entry, index) in savedEntries" :key="index" class="border border-gray-300 p-2">
            {{ getEntryDescription(entry, index) }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(label, key) in resultLabels" :key="key">
          <td class="border border-gray-300 p-2 font-medium">{{ label }}</td>
          <td v-for="(entry, index) in savedEntries" :key="index" class="border border-gray-300 p-2">
            {{ formatResult(key, entry.results[key]) }}
          </td>
        </tr>
      </tbody>
    </table>

    <h2 class="text-xl font-semibold mt-6 mb-2">What the results mean</h2>
    <p class="mb-4">
      This PDF provides a comprehensive overview of your UK property rental calculations. It includes your current calculation, saved entries, and a comparison of all saved entries. Use this information to analyze different scenarios and make informed decisions about your property investments. The descriptions below explain each input and result to help you interpret the data effectively.
    </p>
    <div class="mb-4">
      <h3 class="text-lg font-medium mb-1">Input Field Descriptions</h3>
      <div v-for="field in fields" :key="field.id" class="mb-2">
        <p class="font-medium">{{ field.label }}:</p>
        <p>{{ field.tooltip }}</p>
      </div>
    </div>
    <div class="mb-4">
      <h3 class="text-lg font-medium mb-1">Result Descriptions</h3>
      <div v-for="(tooltip, key) in tooltips" :key="key" class="mb-2">
        <p class="font-medium">{{ resultLabels[key] }}:</p>
        <p>{{ tooltip }}</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps } from 'vue'

const props = defineProps({
  fields: Array,
  results: Object,
  resultLabels: Object,
  savedEntries: Array,
  amortizationSchedule: Array,
  mortgageType: String,
  tooltips: Object
})

const formatResult = (key, value) => {
  if (key.includes('Rate') || key.includes('ROI') || key === 'cashOnCashReturn') {
    return (value * 100).toFixed(2) + '%'
  } else {
    return '£' + value.toFixed(2)
  }
}

const getEntryDescription = (entry, index) => {
  const differentFields = entry.fields.filter(field => 
    props.savedEntries.some(e => e.fields.find(f => f.id === field.id).value !== field.value)
  )

  if (differentFields.length === 0) {
    return `Entry ${index + 1} (Default)`
  }

  const description = differentFields.map(field => {
    let value = field.value
    if (field.prefix) {
      value = `${field.prefix}${value}`
    }
    if (field.suffix) {
      value = `${value}${field.suffix}`
    }
    return `${field.label}: ${value}`
  }).join(', ')

  return `Entry ${index + 1} (${description})`
}
</script>

<style scoped>
.printable-content {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #333;
}
</style>